import { ViewLayoutListColumns } from '@/types/view.types';

export enum ROUTES {
  ROOT = '/',
  DNE = '/doesNotExist',
  PERSONNEL = '/personnel',
  UNAUTHORIZED = '/unauthorized',
  VIEWS = '/views',
  EDIT = '/edit',
}

export const LAYOUT_LIST_PUBLIC_ONLY_COLUMNS = [
  ViewLayoutListColumns.ASSIGNMENT,
  ViewLayoutListColumns.ASSIGNMENT_UNITS,
  ViewLayoutListColumns.DATE,
  ViewLayoutListColumns.LOCATIONS,
  ViewLayoutListColumns.PERSONNEL,
  ViewLayoutListColumns.START_TIME,
  ViewLayoutListColumns.STOP_TIME,
  ViewLayoutListColumns.TEMPLATE,
];
