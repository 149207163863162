import { DateTime } from 'luxon';

import { store } from '@/store/store';

export enum DateFormat {
  ISO_8601 = 'yyyy-MM-dd',
  MONTH_DATE_TIME = 'LLLL dd, HH:mm:ss',
}

export const BrowserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Format an ISO 8601 string to yyyy-MM-dd
 *
 * @param date - the ISO date string
 *
 * @example
 * toSimpleDate('2022-11-27T09:08:23.213')
 */
export const toSimpleDate = (date: string) => {
  return DateTime.fromISO(date).toFormat(DateFormat.ISO_8601);
};


/**
 * Format an ISO 8601 string to LLLL dd, HH:mm:ss
 *
 * @param date - the ISO date string
 *
 * @example
 * toMonthDateTime('2022-11-27T09:08:23.213')
 */
export const toMonthDateTime = (date: string) => {
  return DateTime.fromISO(date).toFormat(DateFormat.MONTH_DATE_TIME);
};

/**
 * Convert date from an ISO 8601 string to browser's timezone and format it
 *
 * @param date - the ISO date string
 * @param format - the format string
 *
 * @example
 * covertDateToBrowsersTimezoneFormatted('2022-11-27T09:08:23.213', 'MM-DD-yyyy')
 */
export const convertDateToBrowsersTimezoneFormatted = (date: string, format: string) => {
  const dateTime = DateTime.fromISO(date, { zone: BrowserTimezone });
  return dateTime.toFormat(format);
};

/**
 * Convert date from an ISO 8601 string to another timezone anf format it
 *
 * @param date - the ISO date string
 * @param timezone - the timezone the date will be converted to
 * @param format - the format string
 *
 * @example
 * covertDateToTimezoneFormatted('2022-11-27T09:08:23.213', 'America/Denver', 'MM-DD-yyyy')
 */
export const convertDateToTimezoneFormatted = (date: string, timezone: string, format: string) => {
  const dateTime = DateTime.fromISO(date, { zone: timezone });
  return dateTime.toFormat(format);
};

/**
 * Converts a provided date to the user's timezone and applies a provided format.
 *
 * @param {string} date - Date string
 * @param {DateFormat} format  - Desired output format, defaults to ISO 8601
 *
 * @returns {null|string} - Returns formatted date in user's timezone or null
 * */
export const getTimezonedDate = (date: string, format: DateFormat = DateFormat.ISO_8601): null | string => {
  const {
    auth: { userPassport },
  } = store.getState();

  const userTz = userPassport?.user?.tz ?? BrowserTimezone;
  const isTzAEnabled = !!userPassport?.user?.parameters?.LBLiteTimeZoneAwareness;

  return isTzAEnabled
    ? convertDateToBrowsersTimezoneFormatted(date, format)
    : convertDateToTimezoneFormatted(date, userTz, format);
};
