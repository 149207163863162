interface ConfigSet {
  lbApiHost: string | undefined;
  lbFallbackLoginUrl: string | undefined;
  clientId: string | undefined;
  lbBaseURL: string | undefined;
  ldClientKey: string | undefined;
  pendoApiKey: string | undefined;
}

export enum ConfigKeys {
  CLIENT_ID = 'clientId',
  LB_FALLBACK_LOGIN_URL = 'lbFallbackLoginUrl',
  LB_API_HOST = 'lbApiHost',
  LB_BASE_URL = 'lbBaseURL',
  LAUNCH_DARKLY_CLIENT_KEY = 'ldClientKey',
  PENDO_API_KEY = 'pendoApiKey',
}

interface Config extends ConfigSet {
  getConfig: (urlName: keyof ConfigSet) => string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const env = (window as any).env ?? {};

export const ConfigHelper: Config = {
  clientId: env.LB_CLIENT_ID ?? import.meta.env.VITE_LB_CLIENT_ID,
  getConfig(urlName) {
    return this[urlName];
  },
  lbApiHost: (() => {
    if (import.meta.env.VITE_NODE_ENV === 'development') {
      console.log('API: ', import.meta.env.VITE_LB_API_HOST);
    }

    return env.LB_API_HOST ?? import.meta.env.VITE_LB_API_HOST;
  })(),
  lbBaseURL: env.LB_BASE_URL ?? import.meta.env.VITE_LB_BASE_URL,
  lbFallbackLoginUrl: env.LB_FALLBACK_LOGIN_URL ?? '',
  ldClientKey:env.LAUNCH_DARKLY_CLIENT_KEY ?? import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_KEY,
  pendoApiKey: env.PENDO_API_KEY ?? import.meta.env.VITE_PENDO_API_KEY,
};
