import React from 'react';

import { useGetUserPassportQuery } from '@/API/api.slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setUserPassport } from '@/store/slices/auth.slice';

const useUserPassport = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  const { data } = useGetUserPassportQuery(undefined, {
    skip: !isAuthenticated,
  });

  const dispatch = useAppDispatch();

  return React.useEffect(() => {
    if (!isAuthenticated) return;

    if (data) {
      dispatch(setUserPassport(data));
    }
  }, [data, dispatch, isAuthenticated]);
};

export default useUserPassport;
