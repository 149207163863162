/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HttpMethods } from 'msw';

import APIConstants, { ContentTypes, Endpoints, HeaderKeys } from '@/constants/api';
import { authSlice } from '@/store/slices/auth.slice';
import { uiSlice } from '@/store/slices/ui.slice';
import { RootState } from '@/store/store';
import { UserPassport } from '@/types/user.types';
import { ConfigHelper, ConfigKeys } from '@/utils/env';

const Error = {
  message: APIConstants.AUTHENTICATION_ERROR_MESSAGE,
  origin: APIConstants.AUTHENTICATION_ERROR_ORIGIN,
  stack: '',
};

export enum GrantType {
  Password = 'password',
  RefreshToken = 'refresh_token',
}

const AUTH_HEADERS = {
  [HeaderKeys.Accept]: ContentTypes.ApplicationJSON,
  [HeaderKeys.ContentType]: ContentTypes.FormEncoded,
};

export interface AuthRequest {
  clientId: string;
  grantType: GrantType.Password;
  password: string;
  username: string;
}

export interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface AuthRefreshRequest {
  clientId: string;
  refreshToken: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: ConfigHelper.getConfig(ConfigKeys.LB_API_HOST) ?? '',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    headers.set(HeaderKeys.ContentType, ContentTypes.ApplicationJSON);

    if (token) {
      headers.set(HeaderKeys.Authorization, `Bearer ${token}`);
      return headers;
    }
  },
});

export const apiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({
    getUserPassport: builder.query<UserPassport, void>({
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
          dispatch(uiSlice.actions.setCriticalError(Error));
        }
      },
      query: () => {
        return {
          headers: { ...AUTH_HEADERS },
          method: HttpMethods.GET,
          url: Endpoints.UserPassport,
        };
      },
    }),
    login: builder.mutation<AuthResponse, Omit<AuthRequest, 'grantType'>>({
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
          dispatch(uiSlice.actions.setCriticalError(Error));
          dispatch(authSlice.actions.revokeAuth());
        }
      },
      query: (credentials) => {
        const formData = new URLSearchParams({
          client_id: ConfigHelper.getConfig(ConfigKeys.CLIENT_ID) ?? '',
          grant_type: GrantType.Password,
          password: credentials.password,
          username: credentials.username,
        });

        return {
          body: formData,
          headers: { ...AUTH_HEADERS },
          method: HttpMethods.POST,
          url: Endpoints.Token,
        };
      },
    }),
    refreshAuth: builder.mutation<AuthResponse, AuthRefreshRequest>({
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (err) {
          console.log(err);
          dispatch(uiSlice.actions.setCriticalError(Error));
        }
      },
      query: (credentials) => {
        const formData = new URLSearchParams({
          // eslint-disable-next-line camelcase
          client_id: ConfigHelper.getConfig(ConfigKeys.CLIENT_ID) ?? '',
          // eslint-disable-next-line camelcase
          grant_type: GrantType.RefreshToken,
          // eslint-disable-next-line camelcase
          refresh_token: credentials.refreshToken,
        });

        return {
          body: formData,
          headers: { ...AUTH_HEADERS },
          method: HttpMethods.POST,
          url: Endpoints.Token,
        };
      },
    }),
  }),
});

export const { useLoginMutation, useRefreshAuthMutation, useGetUserPassportQuery } = apiSlice;
