import { Spinner } from '@chakra-ui/react';
import React from 'react';

import useUserPassport from '@/hooks/useUserPassport';
import { useAppSelector } from '@/store/hooks';

const UserPassport = ({ children }: React.PropsWithChildren): React.JSX.Element => {
  useUserPassport();

  const passport = useAppSelector((state) => state.auth.userPassport);

  if (!passport) return <Spinner />;

  return <>{children}</>;
};

export default UserPassport;
