import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';

import { store } from '@/store/store';
import { ConfigHelper } from '@/utils/env';

const passport = store.getState().auth.userPassport;

const LDProvider = await asyncWithLDProvider({
  clientSideID: ConfigHelper.ldClientKey ?? '',
  context: {
    custom: {
      customerId: passport?.user.customer_id,
      empId: passport?.user.emp_id,
      isAdmin: passport?.user.is_admin,
      userId: passport?.user.user_id,
    },
    email: passport?.user.email,
    key: `${passport?.user.customer_id}_${passport?.user.emp_id}`,
    kind: 'user',
    name: passport?.user.user_name,
  },
});

const LDProviderWrapper = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  return <LDProvider>{children}</LDProvider>;
};

export default LDProviderWrapper;
