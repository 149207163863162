import React from "react";

import { useAppSelector } from "@/store/hooks";
import { ConfigHelper, ConfigKeys } from '@/utils/env';


const Pendo = (): React.JSX.Element => {
  const { userPassport } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    const apiKey =  ConfigHelper.getConfig(ConfigKeys.PENDO_API_KEY);

    const getUserPendoData = () => ({
      visitor: {
        customerName: userPassport?.user?.customer_name,
        email: userPassport?.user?.email,
        id: userPassport?.user?.user_id,
        timezone: userPassport?.user?.tz,
        username: userPassport?.user?.user_name,
      },
    });
  
    if (apiKey) {
      // Initialize pendo object on window.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).pendo = {
        _q: [["initialize", getUserPendoData()]],
      };

      // Load pendo.js
      const script = document.createElement("script");
      // eslint-disable-next-line no-magic-numbers
      const body = document.getElementsByTagName("body")[0];

      script.src = `https://content.analytics.lightning-bolt.com/agent/static/${apiKey}/pendo.js`;
      script.async = true;
      body.appendChild(script);
    }
  }, [userPassport]);

  return <></>;
};

export default Pendo;
